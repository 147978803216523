import React from 'react'
import triokbg from "../../assets/images/trioakBg.png";
import logo from '../../assets/images/Logo.png';
import Footer from './Footer'
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
    return (
        <div>
                {/*----------- tri-oak-header -----------*/}
            
                <div className='tri-oak-header flex justify-between px-[30px] md:px-[80px] py-3'>
                 <div>
                    <Link to='/'><img src={logo} alt='logo'/></Link>
                 </div>
                 <div  >
                    <button
                        className=' bg-[#2D5B30] body-N light-H px-[40px] py-[10px] rounded-3xl '
                        onClick={() => {
                            window.open("https://app.tri-oakcrm.com/login")
                        }}
                    >Login</button>
                </div>
            </div>

             <div className="flex items-center justify-center min-h-screen bg-cover bg-center px-4" style={{ backgroundImage: `url(${triokbg})` }}>
            <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-lg max-w-4xl w-full text-left m-12">
               <div className=' flex justify-center'>
                </div>
                <h2 className="head-1 dark-H mt-6 mb-3">PRIVACY POLICY</h2>
                <p className=" dark-M mb-4">
                    At Tri-oak, we are committed to protecting the privacy of our users. 
                    This Privacy Policy outlines how we collect, use, and disclose personal 
                    information when you use our platform.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Information We Collect</h2>
                <p className=" dark-M mb-4">
                    We collect personal information from you when you register for an account, 
                    use our services, or interact with us through our website or mobile application. 
                    The information we collect may include:
                </p>
                <ul className="list-disc list-inside dark-M mb-4 ml-4">
                    <li>Your name and email address</li>
                    <li>Your phone number and physical address</li>
                    <li>Your login credentials and password</li>
                    <li>Your browser type and operating system</li>
                    <li>Your device's IP address and cookie information</li>
                </ul>

                <h2 className=" head-3 dak-H mt-6 mb-3">How We Use Your Information</h2>
                <p className="dark-M mb-4">
                    We use the information we collect to provide our services, improve our platform, and communicate with you. Specifically, we may use your information to:
                </p>
                <ul className="list-disc list-inside dark-M mb-4 ml-4">
                    <li>Create and manage your account</li>
                    <li>Provide you with access to our services</li>
                    <li>Improve our platform and services</li>
                    <li>Communicate with you about new features, updates, and other important information</li>
                    <li>Monitor and analyze usage patterns to improve our platform</li>
                </ul>

                <h2 className=" head-3 dak-H mt-6 mb-3">Retention and Deletion of Google User Data</h2>
                <p className="dark-M mb-4">
                We handle Google user data in compliance with applicable privacy laws and Google's data policies
                </p>
                  <h4 className='head-3 dak-H'>1. Retention Policy:</h4>
                <ul className="list-disc list-inside dark-M mb-4 ml-4">
                    <li>Google user data is retained only for as long as it is necessary to
                         deliver the services you have authorized
                         (e.g., calendar syncing or reminders).</li>
                    <li>If you disconnect your Google account from our platform,
                         we will no longer access your Google user data.</li>
                </ul>
                 
                <h4 className='head-3 dak-H'>2. Deletion Policy</h4>
                <ul className="list-disc list-inside dark-M mb-4 ml-4">
                    <li>You can revoke our access to your Google account at any time
                         through your Google Account settings.</li>
                    <li>Upon revocation of access or the deactivation of your account on our platform,
                         we will delete all Google user data stored on our servers within 30 days,
                         unless it is required to be retained by law or for compliance purposes.</li>
                    <li>You may also contact us directly at <span className='head-3 dak-H'>dev-serv@tri-oak.com </span>
                        to request expedited deletion of your Google user data.</li>
                </ul>

                <h4 className='head-3 dak-H'>3. Scope of Deletion:</h4>
                <ul className="list-disc list-inside dark-M mb-4 ml-4">
                    <li>Deletion applies to all data linked to Google services,
                         including calendar events, 
                        Google email information, and related integrations.</li>
                </ul>


                <h2 className=" head-3 dak-H mt-6 mb-3">Disclosure of Information</h2>
                <p className="dark-M mb-4">
                    We may disclose your information to third-party service providers who assist us in providing our services. We may also disclose your information if required by law or in response to a legal request.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Security Measures</h2>
                <p className="dark-M mb-4">
                    We take the security of your personal information seriously. We use industry-standard security measures to protect your information from unauthorized access, disclosure, or use.
                </p>

                <h2 className=" head-3 dak-H mt-6 mb-3">Your Rights</h2>
                <p className="dark-M mb-4">
                    You have the right to access and correct your personal information. You can do this by logging into your account and updating your profile information
                </p>

                <h4 className='head-3 dak-H'>1. Access and Correction:</h4>
                  <p className="list-disc list-inside dark-M mb-4 ml-4">You may access and update your information by logging into your
                     account or contacting our support team.</p>

                 <h4 className='head-3 dak-H'>2. Revoke Permissions:</h4>
                  <p className="list-disc list-inside dark-M mb-4 ml-4">You may revoke our access to your Google account through your Google
                     Account settings at any time..</p>   

                   <h4 className='head-3 dak-H'>3. Data Deletion:</h4>
                  <p className="list-disc list-inside dark-M mb-4 ml-4">You may request the deletion of your personal or Google user data 
                    by contacting us directly at <span className='head-3 dak-H'>dev-serv@tri-oak.com</span>.</p>    

                     <h4 className='head-3 dak-H'>4. Transparency:</h4>
                  <p className="list-disc list-inside dark-M mb-4 ml-4">We are committed to being transparent about how we collect, use, and manage your data.
                     If you have any questions, feel free to contact us.
                    </p>      
               


                <h2 className=" head-3 dak-H mt-6 mb-3">Changes to this Policy</h2>
                <p className="dark-M mb-4">
                    We may update this Privacy Policy from time to time. If we make any material changes to this policy, we will notify you by email or through our platform.
                </p>

                <div>
                    <p className='head-3 dak-H'>Tri-Oak</p>
                    <p className='head-3 dak-H'>Support Team</p>
                    <Link to='/' className='head-3 dak-H'>tri-oakcrm.com</Link>
                    <p className='head-3 dak-H'>Email: dev-serv@tri-oak.com</p>
                </div>


            </div>
            </div>
            
            <Footer/>
        </div>
       
    )
}

export default PrivacyPolicy
